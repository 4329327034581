/**
 * @Author        : Jarcem
 * @Date          : 2022-10-09 17:24:50
 * @LastEditTime  : 2023-11-30 16:33:43
 * @LastEditors   : Jarcem
 * @Description   : 
 * @FilePath      : /home-page-of-leo-software/src/router/index.js
 * @
 */
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const Solution = () => import("../pages/solution.vue");
const Index = () => import("../pages/index.vue"); 
const anLi = () => import("../pages/anLi.vue"); 
const cooperation = () => import("@/pages/cooperation.vue"); 
const microbrand = () => import("@/pages/microbrand/microbrand.vue"); 
const routes = [
    
    {
        path: "/",
        name: "index",
        component: Index,
        meta: {
            title: "长沙利奥软件有限公司官方网站｜app开发｜小程序开发｜web网站开发｜企业应用开发｜辅助工具开发｜免费维护｜定制化服务｜PC电脑端应用开发｜移动应用开发",
        },
    },
	{
	    path: "/solution",
	    name: "solution",
	    component: Solution,
	    meta: {
	        title: "行业解决方案",
	    },
	},
	{
	    path: "/anLi",
	    name: "anLi",
	    component: anLi,
	    meta: {
	        title: "案例",
	    },
	},
	{
	    path: "/cooperation",
	    name: "cooperation",
	    component: cooperation,
	    meta: {
	        title: "合作",
	    },
	},
	{
	    path: "/microbrand",
	    name: "microbrand",
	    component: microbrand,
	    meta: {
	        title: "微品牌数智云平台",
	    },
	},
];

const router = new Router({
    routes,
    mode: "history",
    // mode: "hash",
});

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

export default router;
