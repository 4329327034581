<!--
 * @Author        : Jarcem
 * @Date          : 2022-10-09 17:24:50
 * @LastEditTime: 2023-04-20 21:13:04
 * @LastEditors: wangxihan
 * @Description   : 
 * @FilePath      : \home-page-of-leo-software\src\App.vue
 * 
-->
<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			screenWidth: document.body.clientWidth, // 屏幕宽度
			screenHeight: document.body.clientHeight, // 屏幕高度
		}
	},
	beforeCreate() {

	},
	created() {
		let zoom = (this.screenWidth / 1493).toFixed(2)
		if (this.screenWidth < 480) {
			document.querySelector('body').setAttribute('style', 'zoom:' + zoom)
		}

	},
	components: {

	},

}
</script>

<style>
@import './assets/index.css';


#app {
	/* font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50; */
	/* margin-top: 60px; */
}

#app img {
	border-radius: 0.2rem;
}
</style>
